* {
  -webkit-font-smoothing: antialiased;
}

.amplify-tabs {
  display: none;
}

[data-amplify-authenticator] {
  align-items: center;
  height: 100vh;
  width: 100%;
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
}

[data-amplify-authenticator] [data-amplify-container] {
  width: 100%;
  min-width: 64vh;
  height: 100vh;
}

[data-amplify-authenticator] [data-amplify-container] [data-amplify-router] {
  border: 0;
  height: 100vh;
  background-color: #ffffff;
  padding-top: 10vh;
  padding-bottom: 10vh;
  box-shadow: none;
  margin-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

[data-amplify-authenticator]
  [data-amplify-container]
  [data-amplify-router]
  > div {
  width: 100%;
}

[data-amplify-authenticator] [data-amplify-form] {
  padding-left: 25%;
  padding-right: 25%;
  padding-bottom: 2vh;
}

.amplify-label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #242424;
  text-align: left;
}

.amplify-input {
  background: #f8f8f9;
  border: none;
  text-align: left;
}

.amplify-input:hover {
  border-width: 2px;
  box-shadow: none;
}

.amplify-button--primary {
  background: #be7ddb;
  color: white;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}

.amplify-text--error {
  color: #eb5757;
}

.amplify-button {
  border: 0;
}

#radix-8-content-0 > div > div > div:nth-child(3) > div:nth-child(1) > button {
  width: "100%";
}
.amplify-button--primary:hover {
  background: #9a54b9;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}
.amplify-button--normal {
  background: #ffffff;
  border: 1px solid #be7ddb;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  color: #be7ddb;
}
.amplify-button--normal:hover {
  background-color: #ffffff;
  border: 1px solid #9a54b9;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  color: #9a54b9;
}

.amplify-loader {
  --amplify-components-loader-stroke-filled: white;
}

.amplify-button--link {
  font-weight: 600;
  color: #8977e4;
}
.amplify-button--link:hover {
  font-weight: 600;
  background: none;
  color: #8977e4;
}
.amplify-button--link:active {
  font-weight: 600;
  background: none;
  color: #8977e4;
}
.amplify-button__loader-wrapper {
  color: white;
}

.amplify-field-group__outer-start .amplify-field-group__control,
.amplify-field-group__outer-end .amplify-field-group__control {
  background: #f2f2f2;
  border-color: white;
}
